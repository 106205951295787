import React, { ReactNode, useEffect, useRef, useState } from "react"
import { Link } from "src/components/link/Link"
import cn from "classnames"
import { Container } from "../container/Container"

import s from "./Header.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { GlobalSettings, HeaderStyle, LinkItem, PageThemeType } from "src/sanity/types"
import SegmentSelector from "./SegmentSelector"
import { debounce, Only } from "src/utils"
import DownloadOverlay from "src/components/download-overlay/DownloadOverlay"
import Menu from "./Menu"
import MobileNavigation from "./MobileNavigation"
import { useSettings } from "../page/settingsContext"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import buildLink from "../../sanity/buildLink"
import LoginOverlay from "src/components/login-overlay/LoginOverlay"
import { useRouter } from "next/router"
import { getCallToActionTrackingHandler } from "src/components/call-to-action/CallToAction"
import { useExperiments } from "../page/experimentsContext"

interface HeaderProps {
    children?: ReactNode
    globalSettings: GlobalSettings
    style: HeaderStyle
    pageThemeType?: PageThemeType
    isMoonriseSite?: boolean
}

export function Header({ globalSettings, style, isMoonriseSite }: HeaderProps): JSX.Element {
    const headerRef = useRef<HTMLElement>()
    const router = useRouter()
    const [menuOpen, setMenuOpen] = useState(false)
    const { lang, segment, translations: t } = useSettings()
    const [activeMenuItemId, setActiveMenuItemId] = useState<string | null>(null)
    const [activeNavigation, setActiveNavigation] = useState(globalSettings.navigation?.header || [])

    const homeLink: LinkItem = isMoonriseSite ? {
        id: "", title: "", "linkType": "external", type: "link", url: "/",
    } : {
        id: "",
        title: "",
        linkType: "internal",
        type: "link",
        url: {
            type: "frontpage",
            lang,
            segment,
            slug: "",
        },
    }

    const moonriseMenu: Partial<GlobalSettings["navigation"]["header"]> = [
        {
            id: "link1",
            label: "Product",
            type: "linkGroup",
            groupWithPrevious: false,
            children: [
                {
                    type: "linkGroup",
                    label: "What we offer",
                    id: "link1-1",
                    groupWithPrevious: false,
                    children: [
                        {
                            id: "link1-1-1",
                            title: "Accounts",
                            subtitle: "Nordic accounts tailored to your needs",
                            type: "link",
                            linkType: "external",
                            media: {
                                type: "icon",
                                icon: IconType.ACCOUNT,
                                width: null,
                                height: null,
                            },
                            url: "/accounts",
                        },
                        {
                            id: "link1-1-2",
                            title: "Payments",
                            subtitle: "Payments, transfers and FX in real-time",
                            type: "link",
                            linkType: "external",
                            media: {
                                type: "icon",
                                icon: IconType.ARROW_TRANSFER_VERTICAL,
                                width: null,
                                height: null,
                            },
                            url: "/payments",
                        },
                    ],
                },
            ],
        },
        {
            id: "link2",
            title: "Use Cases",
            type: "link",
            linkType: "external",
            url: "/use-cases",
        },
        {
            id: "link3",
            title: "News & Insights",
            type: "link",
            linkType: "external",
            url: "/news",
        },
        {
            id: "link4",
            title: "Developers",
            type: "link",
            linkType: "external",
            url: "https://docs.banking-services.lunar.app",
        },
        {
            id: "link5",
            title: "About us",
            type: "link",
            linkType: "external",
            url: "/about",
        },
    ]


    useEffect(() => {
        if (isMoonriseSite)
            setActiveNavigation(moonriseMenu)
        else
            setActiveNavigation(globalSettings.navigation?.header || [])
    }, [isMoonriseSite])

    useEffect(() => {
        const onScroll = () => {
            const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop
            const viewportHeight = window.innerHeight

            if (scrollPosition > viewportHeight && !document.body.classList.contains("scrolled-past-top-module")) {
                // Page has scrolled past 1 viewport height
                document.body.classList.add("scrolled-past-top-module")
            } else if (scrollPosition < viewportHeight && document.body.classList.contains("scrolled-past-top-module")) {
                document.body.classList.remove("scrolled-past-top-module")
            }
        }
        const debouncedOnScroll = debounce(onScroll, 25)

        window.addEventListener("scroll", debouncedOnScroll)

        return () => {
            window.removeEventListener("scroll", debouncedOnScroll)
        }
    }, [])

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [menuOpen])

    // close menu and popup when going to a different page
    useEffect(() => {
        const routeChangeComplete = () => { setMenuOpen(false); setActiveMenuItemId(null) }

        router.events.on("routeChangeComplete", routeChangeComplete)

        return () => {
            router.events.off("routeChangeComplete", routeChangeComplete)
        }
    }, [router])

    let loginLink: LinkItem
    if (lang === "dk" && segment === "business")
        loginLink = { linkType: "external", title: "Log ind", url: "https://business.lunar.app/auth/signin?country=dk", id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "en" && segment === "business" && !isMoonriseSite)
        loginLink = { linkType: "external", title: "Log in", url: "https://business.lunar.app/auth/signin?country=dk", id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "se" && segment === "private")
        loginLink = { linkType: "event", title: "Logga in", event: "loginOverlay", id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "se" && segment === "business")
        loginLink = { linkType: "external", title: "Logga in", url: "https://business.lunar.app/auth/signin?country=se", id: "login-link", type: "link", gtmEvent: "login-click" }
    if (lang === "no" && segment === "business")
        loginLink = { linkType: "external", title: "Logg inn", url: "https://business.lunar.app/auth/signin?country=no", id: "login-link", type: "link", gtmEvent: "login-click" }

    const hamburgerCTATracker = getCallToActionTrackingHandler({ event: "hamburger-menu", linkType: "event", title: "Hamburger menu" })
    let logo: IconType = IconType.LOGO
    if (segment === "private") logo = IconType.LOGO
    else if (isMoonriseSite) logo = IconType.MOONRISE_LOGO_WITH_LUNAR_CAPTION
    else if (segment === "business") logo = IconType.LOGO_BUSINESS

    const { activeExperiments } = useExperiments()
    const productDirectNav = activeExperiments["lbb_direct_product_nav_link"]?.activeVariant === "direct"

    if (productDirectNav && segment === "business" && lang === "dk") {
        activeNavigation[0] = {
            "id": "link1",
            "type": "link",
            "linkType": "internal",
            "title": "Produkter",
            "url": {
                "lang": "dk",
                "segment": "business",
                "slug": "produkt",
                "type": "page",
            },
        }
    }

    return (
        <>
            <header
                ref={headerRef}
                className={cn(
                    s["header"],
                    s["header--" + style],
                )}
            >
                <Container>
                    <nav className={s["header__inner"]} >
                        <Link href={buildLink(homeLink)} className={cn(s["header__logo"], segment === "business" && !isMoonriseSite ? s["header__logo--business"] : null)}>
                            <Icon type={logo} />
                            <span className="visually-hidden">
                                Lunar {t?.header?.home.toLocaleLowerCase()}
                            </span>
                        </Link>

                        <div className={s["header__nav"]}>
                            {!isMoonriseSite && (
                                <div className={s["header__segment"]}>
                                    <SegmentSelector dark={segment === "business"} />
                                </div>
                            )}

                            <ul className={cn(s["header__menu"], activeMenuItemId !== null ? s["header__menu--is-active"] : null)} onMouseLeave={() => setActiveMenuItemId(null)}>
                                {activeNavigation.map((i) => {

                                    if (i.type !== "linkGroup") {
                                        if (i.linkType === "event") {
                                            return (
                                                <li className={cn(s["header__menu__item"], activeMenuItemId === i.id ? s["header__menu__item--active"] : null, s["header__menu__item--last"])} key={i.id}>
                                                    <LinkHandler link={i} className={cn(s["header__menu-link"], "label-100")} prefetch={false} onMouseEnter={() => setActiveMenuItemId(i.id)} />
                                                </li>
                                            )
                                        }
                                        return (
                                            <li className={cn(s["header__menu__item"], activeMenuItemId === i.id ? s["header__menu__item--active"] : null)} key={i.id}>
                                                <LinkHandler link={i} className={cn(s["header__menu-link"], "label-100")} prefetch={false} onMouseEnter={() => setActiveMenuItemId(i.id)} />
                                            </li>
                                        )
                                    }


                                    return (
                                        <li className={cn(s["header__menu__item"], i.children.length > 1 ? s["header__menu__item--big"] : "", activeMenuItemId === i.id ? s["header__menu__item--active"] : null)} key={i.id}  >
                                            <Menu
                                                big={i.children.length > 1}
                                                id={i.id}
                                                label={i.label}
                                                isActive={activeMenuItemId === i.id}
                                                onMouseEnter={() => setActiveMenuItemId(i.id)}
                                            >
                                                {i.children}
                                            </Menu>
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>
                        <LoginOverlay />
                        <div className={cn(s["header__right-side"])}>
                            <Only if={loginLink}>
                                <LinkHandler className={cn(s["header__login"], "label-100")} link={loginLink} />
                            </Only>
                            <Only if={globalSettings.headerCTALabel}>
                                <div className={s["header__download"]}>
                                    <DownloadOverlay />
                                </div>
                            </Only>

                            <div className={s["header__open-menu"]}>
                                <button onMouseDown={hamburgerCTATracker} onClick={() => setMenuOpen(i => !i)}>
                                    <Icon type={IconType.HAMBURGER} />
                                </button>
                            </div>
                        </div>
                    </nav>
                </Container>
            </header>
            {menuOpen &&
                <MobileNavigation navigation={activeNavigation} menuOpen={menuOpen} loginLink={loginLink} close={() => setMenuOpen(false)} />
            }
            <div className={cn(s["header__backdrop"], activeMenuItemId ? s["header__backdrop--active"] : null)} />
        </>
    )
}

